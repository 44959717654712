import React, { useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaLocationCrosshairs, FaLinkedin } from "react-icons/fa6";
import { FiPhoneCall } from "react-icons/fi";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Contact = () => {
  const container = useRef();
  const form = useRef();
  const messageRef = useRef(); // ייחוס להודעת ההצלחה
  const [message, setMessage] = useState(""); // הודעה לאחר השליחה

  useGSAP(
    () => {
      gsap
        .timeline({
          delay: 0.5,
          scrollTrigger: {
            trigger: container.current,
            start: "20% bottom",
            end: "bottom top",
          },
        })
        .fromTo(".form", { x: 50, opacity: 0 }, { x: 0, opacity: 1 })
        .fromTo(
          ".contact__infos",
          { y: 100, opacity: 0 },
          { y: 0, opacity: 1 }
        );
    },
    { scope: container }
  );

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_z6sju9j", // service ID
        "template_o632v84", // template ID
        form.current,
        "b0L55APhAFXnWbWwo" // public key
      )
      .then(
        () => {
          setMessage("האימייל נשלח בהצלחה!"); // עדכון ההודעה
          showAnimatedMessage(); // הפעלת האנימציה
        },
        (error) => {
          setMessage("שליחת האימייל נכשלה, נסה שוב.");
          showAnimatedMessage();
          console.error("FAILED...", error.text);
        }
      );
  };

  // הפונקציה תפעיל את האנימציה על הודעת ההצלחה
  const showAnimatedMessage = () => {
    gsap.fromTo(
      messageRef.current,
      { y: -50, opacity: 0 }, // מתחילה למעלה ולא נראית
      { y: 0, opacity: 1, duration: 0.5, ease: "power2.out" } // נגללת למטה
    );

    // אפשרות: הסתר את ההודעה לאחר כמה שניות
    setTimeout(() => {
      gsap.to(messageRef.current, { y: -50, opacity: 0, duration: 0.5 });
    }, 7000);
  };

  return (
    <section id="contact" ref={container} dir="rtl">
      {/* הודעת הצלחה מונפשת */}
      {message && (
        <div ref={messageRef} className="animated-message">
          {message}
        </div>
      )}

      {/* צד שמאל - תמונה */}
      <div className="contact-image"></div>

      {/* צד ימין - טופס ופרטי קשר */}
      <div className="container">
        <form ref={form} onSubmit={sendEmail} className="form">
          <div className="form__top">
            <h3 className="sub__heading">צרו קשר</h3>
            <p className="muted">השאירו פרטים מטה ואנחנו כבר נחזור אליכם.</p>
          </div>
          <label>שם</label>
          <input type="text" name="user_name" required />
          <label>אימייל</label>
          <input type="email" name="user_email" required />
          <label>הודעה</label>
          <textarea name="message" required />
          <input type="submit" value="שלח" />
        </form>

        {/* Contact Information Section */}
        <div className="contact__infos">
          {/* Email */}
          <div className="flex contact__info">
            <div className="flex__center icon__container">
              <MdOutlineAlternateEmail />
            </div>
            <div className="details">
              <h4>שלחו לנו אימייל</h4>
              <p>
                <a href="mailto:barcargo24.riki@outlook.com">
                  barcargo24.riki@outlook.com
                </a>
              </p>
            </div>
          </div>

          {/* Address */}
          <div className="flex contact__info">
            <div className="flex__center icon__container">
              <FaLocationCrosshairs />
            </div>
            <div className="details">
              <h4>משרדינו יושבים בכתובת</h4>
              <p>שבטי ישראל 33, ראשון לציון</p>
            </div>
          </div>

          {/* Phone */}
          <div className="flex contact__info">
            <a href="tel:+9720507350087" className="flex__center icon__container">
              <FiPhoneCall />
            </a>
            <div className="details">
              <h4>חייגו אלינו</h4>
              <p>
                <a href="tel:+9720507350087">+972 073-50087</a>
              </p>
            </div>
          </div>

          {/* LinkedIn */}
          <div className="flex contact__info">
            <div className="flex__center icon__container">
              <FaLinkedin />
            </div>
            <div className="details">
              <h4>LinkedIn</h4>
              <p>
                <a
                  href="https://www.linkedin.com/in/bar-cargo-4a54a5239"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  linkedin.com/in/bar-cargo-4a54a5239
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
