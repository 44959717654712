import React from "react";
import { Link } from "react-scroll";
import "./SeaShipping.css";
import SeaShippingImage from "../../assets/SeaShipping.png";

const SeaShipping = () => {
  return (
    <div id="sea-shipping" className="overlay" dir="rtl">
      <div className="container">
        <div className="sea-shipping-section">
          <div className="sea-shipping-container">
            <div className="sea-shipping-content">
              <h2 className="sea-shipping-title">שילוח ימי</h2>
              <p className="sea-shipping-description">משלוחים אשר זקוקים לקיבולת גבוהה, עמידות לאורך זמן (סחורות אשר נדרשות לאחסון במשך זמן ארוך יותר במכולות), תיכנון גמיש ועלות נמוכה הם משלוחים ששילוח ימי תפור עבורם.
              </p>
              <br/>
              
              <p>אנו בבר קרגו יודעים להלביש בדיוק את אופן השילוח לפי צרכיו של הלקוח.</p><br/>
              <ul className="sea-shipping-list">
                <li>ניתן הצעת מחיר שכוללת את כל העלויות (במטרה להקל על היבואן / יצואן)
                </li>
                <li>נבדוק כל הצעת מחיר מול כמה ספקי שירות: סוכנים, חברות ספנות, חברות תעופה.</li>
                <li>נעקוב ונעדכן את הלקוח בזמן אמת על סטטוס המשלוח עד הגעתו באופן סופי ליעד.</li>
                <li>אנו זמינים 24/7 לכל פניה / שאלה מהלקוח, הסוכנים או ספקי השירות.</li>
              </ul>
              {/* <Link
                to="services"
                smooth={true}
                duration={500}
                className="sea-shipping-btn"
              >
                לעבור לשירותים
              </Link> */}
            </div>
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeaShipping;
