import React from "react";
import "./Footer.css";
import Logo from "../../components/Logo";
import { footer } from "../../source";

const Footer = () => {
  return (
    <section id="footer" dir="rtl">
      <div className="overlay">
        <div className="container">
          <div className="column">
            {/* תוכל להוסיף תוכן נוסף כאן */}
          </div>
        </div>
        <div className="footer__bottom">
          <p>
            האתר נבנה על ידי&nbsp;
            <a
              href="http://www.more-ways.co.il"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
            >
              more-ways
            </a>{" "}
            &copy; Copyright
          </p>
        </div>
      </div>
    </section>
  );
};

export default Footer;
