import Navbar from "./Sections/Navbar";
import Header from "./Sections/Header";
import Services from "./Sections/Services";
import About from "./Sections/About";
import Testimonial from "./Sections/Testimonial";
import Contact from "./Sections/Contact";
import Footer from "./Sections/Footer";
import Background from "./components/Background/Background"; 
import AirShipping from "./Sections/AirShipping/AirShipping";
import SeaShipping  from "./Sections/SeaShipping/SeaShipping";
import CustomsClearance  from "./Sections/CustomsClearance/CustomsClearance";
import LandTransport  from "./Sections/LandTransport/LandTransport";
import Droppers  from "./Sections/Droppers/Droppers";
import Project  from "./Sections/Project/Project";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import WhatsAppButton from "./components/WhatsAppButton/WhatsAppButton"
import "swiper/css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
           <Background />
      <Navbar />
      <Routes>
      <Route path="/" element={
    <>
      <Header />
      <AirShipping /> 
      <SeaShipping />
      <CustomsClearance/>
      <LandTransport/>
      <Droppers/>
      <Project/>
      <FloatingWhatsApp />
    </>
  } />
        <Route path="services" element={<Services />} />
        <Route path="about" element={<About />} />
        <Route path="testimonials" element={<Testimonial />} />
        <Route path="contact" element={<Contact />} />
      </Routes>
      <WhatsAppButton/>
      <Footer />
    </Router>
  );
}

export default App;