import {
    FaTools,
    FaCheckCircle,
    FaClock,
    FaDraftingCompass,
    FaUserFriends,
    FaFacebook,
    FaInstagram,
    FaLinkedin,
    FaYoutube,
    FaHome,
    FaBuilding,
  } from "react-icons/fa";
  import {
    user1,
    user2,
    user3,
    user4,
  } from "./assets";


import testemonial1 from "./assets/testemonial1.jpg";
import testemonial2 from "./assets/testemonial2.jpg";
import testemonial3 from "./assets/testemonial3.jpg";
import testemonial4 from "./assets/testemonial4.png";


  export const navigations = [
    {
      label: "בית",
      to: "/",
    },

    {
      label: "אודות",
      to: "/about", // Updated for routing
    },
    {
      label: "חוות דעת",
      to: "/testimonials", // Updated for routing
    },
    {
      label: "צור קשר",
      to: "/contact", // Updated for routing
    },
  ];

  export const keypoints = [
    {
      title: "Expert Craftsmanship",
      description:"Every detail matters. We ensure high-quality workmanship in every phase of construction."
    },
    {
      title: "On-Time, Within Budget",
      description:"Efficiency is key. We deliver your projects on schedule, without unexpected costs."
    },
    {
      title: "Innovative Solutions",
      description:"We use the latest technologies and sustainable materials to build for the future."
    },
    {
      title: "Client-Centered Approach",
      description:"We listen to your needs and tailor our services to exceed your expectations."
    },
  ];
  
  export const whyChooseUs = [
    {
      title: "Unmatched Quality",
      icon: <FaCheckCircle />,
      description: "Craftsmanship guaranteed",
      id: "quality",
    },
    {
      title: "Timely Delivery",
      icon: <FaClock />,
      description: "Projects completed on schedule",
      id: "delivery",
    },
    {
      title: "Innovative Designs",
      icon: <FaDraftingCompass />,
      description: "Creative and modern solutions",
      id: "designs",
    },
    {
      title: "Focused Service",
      icon: <FaUserFriends />,
      description: "Prioritizing client satisfaction always",
      id: "service",
    },
  ];
  
  export const services = [
    {
      title: "Residential Construction",
      icon: <FaHome />,
      description: `Building modern, high-quality homes tailored to your vision.`,
    },
    {
      title: "Commercial Projects",
      icon: <FaBuilding />,
      description: `Developing functional, innovative commercial spaces for businesses.`,
    },
    {
      title: "Renovation & Remodeling",
      icon: <FaTools />,
      description: `Transforming existing spaces with expert renovation and design.`,
    },
  ];
  
  export const testimonials = [
    {
      name: "מנכ''ל חברת פלסטיקה",
      image: testemonial4,
      review: `לאורך השנים עבדנו עם ספקים רבים, אך מעטות החברות שהפגינו רמה כה גבוהה של מקצועיות ומסירות כמו חברת בר קרגו.
כחברה שמבצעת יבוא בהיקף רחב, כולל תהליכי עמילות מכס, חשוב לנו לעבוד עם ספק שמבין את הצרכים הייחודיים שלנו. 
בר קרגו תמיד מספקת פתרונות לוגיסטיים מדויקים, מהירים ואמינים, תוך הקפדה על עמידה בלוחות זמנים קשוחים, דבר שהוא קריטי עבורנו. פעם שיש אתגר – בין אם מדובר בשילוח רגיש או בפתרון בעיות מפתיעות, בצורה חלקה ומקצועית.
בנוסף, השירותים הנלווים שהם מספקים, כמו עמילות מכס וייעוץ לוגיסטי, חוסכים לנו זמן רב והופכים את כל התהליך לפשוט ויעיל יותר. אנחנו מרגישים ביטחון רב לדעת שיש לנו שותף לדרך שיכול להתמודד עם כל אתגר לוגיסטי שעולה..
`,

    },
    {
      name: "מנכ''ל בחברה ביטחונית",
      image: testemonial1,
      review: `הקשר שלנו עם ריקי והצוות מתבסס לא רק על מקצועיות, אלא גם על אמון מלא. הם מסייעים לנו בניהול תהליכים לוגיסטיים מורכבים, ויודעים להעניק לנו שירות אישי עם יחס אישי, תוך הבנה מעמיקה של הצרכים העסקיים שלנו. בכל פעם שיש אתגר – בין אם מדובר בשילוח רגיש או בפתרון בעיות מפתיעות, בר קרגו מתמודדים בצורה חלקה ומקצועית.
בנוסף, השירותים הנלווים שהם מספקים, כמו עמילות מכס וייעוץ לוגיסטי, חוסכים לנו זמן רב והופכים את כל התהליך לפשוט ויעיל יותר. אנחנו מרגישים ביטחון רב לדעת שיש לנו שותף לדרך שיכול להתמודד עם כל אתגר לוגיסטי שעולה.
אני ממליץ בחום על בר קרגו לכל חברה שזקוקה לשירותי שילוח והובלה בינלאומיים. בזכות השירות המדהים שקיבלנו לאורך השנים, נוצרה מערכת יחסים ארוכת טווח שתמשיך, ללא ספק, גם בעתיד.
.`,
 // ייבוא ישיר
    },
    {
      name: "מנהל מחלקת פרויקטים",
      image: testemonial2,
      review: `"חברת בר קרגו אמנם חברה צעירה יחסית, אולם הצוות שלה עתיר ניסיון בתחום המדובר ובעל יכולות מוכחות.
החוויה שלנו כלקוח של חברת בר קרגו היא כזו שמאפשרת לנו להגיב מהר, להיות אטרקטיביים במחיר ומעל הכל למצוא פתרונות יצירתיים לבעיות שילוח מורכבות ולא פשוטות, דבר שמייצר לנו יתרונות יחסיים על המתחרים שלנו.
אם שואלים אותי – בר קרגו !"
.`,
 // ייבוא ישיר
    },
    {
      name: "מנהל מחלקת שירות לקוחות",
      image: testemonial3,
      review: `"לעבוד עם בר קרגו זו הנאה צרופה,  זו חברה שהיא הדובדבן שבקצפת.
זה כמו לעבוד עם משפחה בשילוב של מקצועיות ויחס אישי.
תשובות מהירות, אמינות, יחס חם , קשובים לבקשות שלנו וגם בקשות מיוחדות, מקצועיות ועזרה בכל שעות היום.
תמיד זמינים והתשובות מאוד מהירות. יודעים לפתור בעיות בצורה מאוד מקצועית.
תודעת השירות של בר קרגו מאוד גבוהה וזו נקודה מספר אחת בשבילי כלקוח."

.`,
 // ייבוא ישיר
    },

  ];
  
  export const footer = [
    {
      title: "Company",
      routes: [
        {
          name: "About Us",
          href: "#about",
        },
        {
          name: "Careers",
          href: "#",
        },
        {
          name: "Contact Us",
          href: "#contact",
        },
      ],
    },
    {
      title: "Services",
      routes: [
        {
          name: "Residential Construction",
          href: "#",
        },
        {
          name: "Commercial Projects",
          href: "#",
        },
        {
          name: "Renovation & Remodeling",
          href: "#",
        },
        {
          name: "Project Management",
          href: "#",
        },
      ],
    },
    {
      title: "Resources",
      routes: [
        {
          name: "Case Studies",
          href: "#",
        },
        {
          name: "FAQs",
          href: "#",
        },
        {
          name: "Privacy Policy",
          href: "#",
        },
      ],
    },
  ];
  
  export const socialHandles = [
    {
      name: "Facebook",
      icon: <FaFacebook />,
      link: "https://www.facebook.com",
    },
    {
      name: "Instagram",
      icon: <FaInstagram />,
      link: "https://www.instagram.com",
    },
    {
      name: "LinkedIn",
      icon: <FaLinkedin />,
      link: "https://www.linkedin.com",
    },
    {
      name: "Youtube",
      icon: <FaYoutube />,
      link: "https://www.youtube.com/channel/UCEtnsPZQEd0l1tbr_nDQd5Q?sub_confirmation=1",
    },
  ];