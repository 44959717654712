import React, { useRef } from "react";
import aboutt from "../../assets/bg-about.jpg"; // תיקון הייבוא
import { FaCheck } from "react-icons/fa";
import Achievement from "../../components/Achievement";
import "./About.css";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const container = useRef();
  useGSAP(
    () => {
      gsap
        .timeline({
          delay: 0.5,
          scrollTrigger: {
            trigger: container.current,
            start: "20%% bottom",
            end: "bottom top",
          },
        })
        .fromTo(".about__image", { x: -50, opacity: 0 }, { x: 0, opacity: 1 })
        .fromTo(
          [
            ".title",
            ".sub__heading",
            ".section__header .description",
            ".keypoint",
          ],
          { opacity: 0 },
          { opacity: 1, stagger: 0.5 }
        );
    },
    { scope: container }
  );

  return (
<section id="about" dir="rtl">
  <div className="half-circle">
    <h3 className="about-title">אודות</h3>
    <p className="p-about">
    חברת בר קרגו נוסדה בשנת 2020, ע"י ריקי בר מנכל"ית החברה. 
<br/>
ריקי, בעלת ניסיון של 40 שנה בתחום שרשרת האספקה, המנווטת את הצוות ביד רמה למצוינות מול הלקוחות. 
<br/>
במהלך השנים ריקי הובילה פרויקטים גדולים ומורכבים ואף שירתה לקוחות מהשורה הראשונה בייבוא ובייצוא מול ישראל. 
<br/>
בחברת בר קרגו הלקוח הוא מעל הכל, ולכן אנו דואגים שהמשלוחים יתנהלו במקצועיות ויצירתיות, תוך הבנת צורכי הלקוח ותפירת פתרונות בזמן אמת. 
<br/>
בחברתנו אנו עובדים מול סוכנים בכל העולם, כולל סוכני נישה, שמטפלים במשלוחים חריגים / פרויקטים על מנת לתת ללקוח מגוון אפשרויות. 
<br/>
בין לקוחותינו אפשר למצוא את עסקי הברזל, השקיה, פלסטיקה, חקלאות ועוד... 
<br/>
אנו מתנהלים באופן טכנולוגי ואישי מול ספקי השירות: חב' ספנות, חב' תעופה, מובילים יבשתיים ועוד. 
<br/>
הצוות מציע זמינות אמיתית של 24/7, תוך מתן מענה מידי ללקוח. 
<br/>
אנו מציעים חוויית שירות בוטיק עם מחירים הוגנים בשוק. 
    </p>
  </div>
  <div className="image-container">
    <img src={aboutt} alt="About" />
  </div>
</section>

  );
};

export default About;
