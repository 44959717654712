import React from "react";
import { Link } from "react-scroll";
import "./CustomsClearance.css";
import CustomsClearanceImage from "../../assets/CustomsClearance.jpg";

const CustomsClearance = () => {
  return (
    <div id="customs-clearance" className="overlay">
      <div className="container">
        <div className="customs-clearance-section">
          <div className="customs-clearance-container">
            <div className="customs-clearance-content"  dir="rtl">
              <h2 className="customs-clearance-title">עמילות מכס</h2>
              <p className="customs-clearance-description">
              אנו בבר קרגו נעשה כל שביכולתנו כדי לייצג יבואן או יצואן מול רשויות המכס באופן שעונה על כל צרכיו של הלקוח.

              </p><br/>
              <p>שירות שכולל הגשת מסמכים, תשלום מיסים, והבטחה שהמוצרים עומדים בדרישות החוקיות של המדינה.

              </p><br/>
              <p>חלק מתהליך העמילות הוא:

              </p>
              <ul className="customs-clearance-list">
                <li>קבלת ניירת מהיבואן / יצואן.
                </li>
                <li>הניירת מסווגת ונבדקת למפרע.
                </li>
                <li>באם נדרש תשלומי מכס עדכניים מאשרים עם הלקוח לפני התהליך.
                </li>
                <li>תיקי הייבוא מסווגים ומוכנים לפני הגעת המשלוח, בכדי שהמשלוח ישוחרר מיידית עם 
           הגעתו ולא יצבור עלויות נוספות.
 </li>
              </ul>
              {/* <Link
                to="services"
                smooth={true}
                duration={500}
                className="customs-clearance-btn"
              >
                לעבור לשירותים
              </Link> */}
            </div>
     
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomsClearance;
