import React from 'react'
import "./Logo.css"
import logo from "../../assets/logo.png"

const Logo = () => {
  return (
    <div className='flex__center logo__container'>
        <div className="flex__center icon__container">
            <img src={logo} alt="" />
        </div>
      
    </div>
  )
}

export default Logo
