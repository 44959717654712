import React, { useState } from "react";
import "./Navbar.css";
import Logo from "../../components/Logo";
import { navigations } from "../../source";
import ThemeToggle from "../../components/ThemeToggle";
import { FiMenu } from "react-icons/fi";
import SocialHandles from "../../components/SocialHandles";
import { FaTimes } from "react-icons/fa";
import useNavbar from "../../hook/useNavbar";
import { Link } from 'react-router-dom';  // עדכון Import מ-react-router-dom

const Navbar = () => {
  const { showNavbar } = useNavbar();
  const [openSidebar, setOpenSidebar] = useState(false);

    // פונקציה לגלילה לראש העמוד
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };




  return (
    <nav className={`flex__center navbar ${showNavbar && "drop"}`}dir="rtl">
            <div onClick={scrollToTop} style={{ cursor: "pointer" }}>
      <Logo />
      </div>
      
      <div className={`flex navbar__links ${openSidebar && "visible"}`}>
        <button
          className="flex__center icon__container cancel__btn"
          onClick={() => setOpenSidebar(!openSidebar)}
        >
          <FaTimes />
        </button>
        {navigations.map((item, index) => (
          <Link
            to={item.to}  // השתמש ב-to עבור ניווט לכתובת URL
            className="nav__item"
            key={index}
          >
            {item.label}
          </Link>
        ))}
      </div>
      <SocialHandles />
      <div className="flex__center">
    
    
        <button
          className="flex__center icon__container menu__btn"
          onClick={() => setOpenSidebar(!openSidebar)}
        >
          <FiMenu />
        </button>
   
      </div>
    </nav>
  );
};

export default Navbar;