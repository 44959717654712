import React from "react";
import { Link } from "react-scroll";
import "./Project.css";
import ProjectImage from "../../assets/project.jpg"; // עדכן את הנתיב לתמונה המתאימה

const Project = () => {
  return (
    <div id="project" className="overlay">
      <div className="container">
        <div className="project-section">
          <div className="project-container">
            <div className="project-content" dir="rtl">
              <h2 className="project-title">פרויקטים</h2>
              <p className="project-description">משלוחים לפרויקטים גדולים הם חלק קריטי בניהול לוגיסטי, וכוללים תכנון וביצוע של הובלת סחורות, חומרים, וכלים הכרחיים לאתרי עבודה.
                <br/>
אנו בבר קרגו נקבל את כל נתוני הפרויקט מהיבואן / היצואן כולל זמני התחלה וסיום הפרויקט הנדרשים ונתמחר את המשלוח לפי כך.
<br/>
התמחור יעשה בהתאם למבוקש מול ספקי נישה המתמחים בפרויקטים מיוחדים. כגון:       <br/>

משלוחים צבאיים, משלוחים חריגים, משלוחים פוליטים וכו'.        <br/>

וכמו בכל המשלוחים אנו נעקוב ונעדכן את הלקוח בזמן אמת על סטטוס המשלוח עד הגעתו באופן סופי ליעד.

              </p>
         
           
            
            </div>
        
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
